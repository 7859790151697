<template>
  <div class="market-solution-section-list-wrap" v-if="listData.list && listData.list.length">
    <div class="rno-lattice-card rno-lattice-cols-4 rno-lattice-cols-m-2 rno-lattice-cols-s-1 rno-lattice-without-margin">
      <div class="rno-lattice-cell" v-for="item in listData.list">
          <div class="rno-14-media-panel ">
            <div class="rno-14-media-panel-object">
              <img class="rno-14-media-panel-icon" :src="item.companyLogo" style="cursor: pointer;" @click="go_detail(item)">
            </div>
            <div class="rno-14-media-panel-body">
              <h4 class="rno-14-media-panel-title" style="cursor: pointer;" @click="go_detail(item)">{{item.companyName || '-'}}</h4>
              <p class="rno-14-media-panel-desc rno-line-clamp-5">{{item.companyDesc}}</p>
              <p class="rno-14-media-panel-desc rno-line-clamp-1">
                行业经验：{{item.industryExperience < 0 ? '若干月' : item.industryExperience+'年以上'}}
              </p>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jump } from '../../util/jumpPage'

export default {
  name: 'ProviderMainlistV2',
  props: {
    listData: {
      type: Object,
      default: {
        total: 0,
        list: []
      }
    }
  },
  methods: {
    go_detail(item) {
      let url = item.cooperationType == 2 ? 'providerdetail' : 'partnerdetail'
      window.location.href = `/market/${url}/${item.id}`
    },
  }
}
</script>
